import styled from "@emotion/styled";
import React, { FC } from "react";
import { Despacho, Salon } from "../Components/Imports";
export const LawFirm: FC = () => {
  const Texts = [
    "El Despacho Jurídico Morán Franco fue fundado en el año 2013 por Roberto Morán Franco, profesor de Derecho y Letrado de los Colegios de Madrid y León, con una amplia experiencia en la Jurisdicción Penal. A lo largo de estos más de 10 años el Despacho Jurídico se ha especializado en Delitos contra la Salud Pública (tráfico de drogas), Blanqueo de Capitales, Delitos de Lesiones, Delitos contra la Seguridad Vial, Delitos de Odio, Delitos contra los Extranjeros y Procedimientos de Extranjería, tanto de arráigo, nacionalidad, reagrupación de familiares o recursos contra las resoluciones de la Administración.",
    "Con sede en Avenida del Mediterráneo 7 de Madrid, el Despacho Jurídico Morán Franco presta asistencia letrada en toda España, dedica en exclusiva al Derecho Penal y la Extranjería, siendo nuestra misión buscar la mejor solución para las personas que confían en nosotros.",
    "La trayectoria de nuestro fundador, así como nuestros numerosos casos de éxito en el ámbito penal y de extranjería, nos avalan como uno de los despachos jurídicos más prestigiosos y de mayor éxito de Madrid. La atención personalizada en todos y cada uno de los procesos por Roberto Morán Franco garantiza al cliente la dedicación que merece.",
    "Nuestro bufete es la mejor opción para la defensa de los intereses de la persona que se ve inmersa en un proceso judicial, porque nuestro cliente sabe que al confiar en nosotros cuenta con la experiencia, la profesionalidad y la lealtad de grandes profesionales en el ámbito de la Defensa Penal.",
  ];
  const Mission = [
    "Desde el bufete concebimos la defensa de nuestros clientes como un deber que requiere la atención personalizada, por eso todos nuestros casos son dirigidos personalmente por Roberto Morán, que elabora y ejercita la estrategia procesal asistido por profesionales del Despacho como abogados, economistas, informáticos o investigadores, todos ellos altamente cualificados.",
    "Desde nuestro Despacho Jurídico ponemos a su servicio la profesionalidad y experiencia necesaria para obtener el mejor resultado para sus intereses.",
    "Quien confía en Morán Franco puede estar seguro de que su defensa penal está en las mejores manos. Nuestra única misión es defender los intereses de las personas que deciden confiar en nosotros.",
  ];

  return (
    <LawFirmContainer>
      <ContainerMainImage>
        <Title className="ElDespacho">
          <h1>EL DESPACHO</h1>
        </Title>
      </ContainerMainImage>
      <ContainerItem className="LawFirm">
        <Title className="TitleDespacho">
          <h1>Quienes Somos</h1>
        </Title>
        <ContainerText>
          {Texts.map((text, index) => {
            return (
              <Text key={index}>
                <p>{text}</p>
              </Text>
            );
          })}
        </ContainerText>
      </ContainerItem>
      <ContainerImage>
        <Image src={Salon} alt="Despacho" />
      </ContainerImage>
      <ContainerItem className="Mission">
        <Title className="TitleMission">
          <h1>Nuestra Misión</h1>
        </Title>
        <ContainerText className="MissionText">
          {Mission.map((mission, index) => {
            return (
              <Text key={index}>
                <p>{mission}</p>
              </Text>
            );
          })}
        </ContainerText>
      </ContainerItem>
    </LawFirmContainer>
  );
};

const LawFirmContainer = styled.div``;
//Imagen
const ContainerMainImage = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Despacho});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (min-width: 1025px) {
    padding: 0 20% 0 20%;
  }
`;

const Title = styled.div`
  &.ElDespacho {
    width: 100%;
    text-align: center;
    color: white;
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(43, 73, 103);
      background: linear-gradient(
        90deg,
        rgba(43, 73, 103, 1) 0%,
        rgba(244, 244, 244, 1) 90%
      );
    }
  }

  &.TitleDespacho {
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(155, 1, 1);
      background: linear-gradient(
        90deg,
        rgba(155, 1, 1, 1) 0%,
        rgba(218, 161, 161, 1) 70%,
        rgba(244, 244, 244, 1) 100%
      );
    }
  }

  &.TitleMission {
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(155, 1, 1);
      background: linear-gradient(
        90deg,
        rgba(155, 1, 1, 1) 0%,
        rgba(218, 161, 161, 1) 70%,
        rgba(244, 244, 244, 1) 100%
      );
    }
  }
`;

const Text = styled.div``;

const ContainerItem = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 3% 15% 3% 15%;
  }
  @media screen and (max-width: 1024px) {
    padding: 3% 6% 3% 6%;
  }
`;

const ContainerText = styled.div`
  padding: 0 3% 0 3%;
`;

const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: 1025px) {
    padding: 3% 15% 3% 15%;
  }
  @media screen and (max-width: 1024px) {
    padding: 3% 6% 3% 6%;
  }
  background-color: black;
`;
const Image = styled.img`
  width: 50%;
  height: 50%;
`;
