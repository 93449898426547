import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorFound } from "./404";
import styled from "@emotion/styled";
import { Privacidad } from "../Components/Imports";
//{title:"",text:""},
const Texts = [
  {
    path: "Politica_de_Privacidad",
    title: "Politica de Privacidad",
    text: [
      {
        title: "",
        text: "Bienvenido/a a Morán Franco. Al acceder y utilizar este sitio web, aceptas los términos y condiciones que se detallan a continuación. Por favor, léelos detenidamente antes de continuar.",
      },
      {
        title: "Propiedad Intelectual",
        text: "Todos los derechos de propiedad intelectual de este sitio web y su contenido, incluyendo pero no limitado a textos, imágenes, logotipos, diseños, gráficos, iconos y otros elementos, son propiedad exclusiva de Morán Franco o de sus respectivos titulares de derechos. Está prohibida cualquier reproducción, distribución, comunicación pública, modificación o cualquier otro uso sin el consentimiento previo por escrito de Morán Franco o los titulares de derechos correspondientes.",
      },
      {
        title: "Responsabilidad",
        text: "Morán Franco se esfuerza por mantener la información del sitio web precisa y actualizada, pero no garantiza su exactitud o integridad. El uso de la información proporcionada en este sitio web es bajo tu propio riesgo. Morán Franco no será responsable por cualquier daño directo, indirecto, incidental o consecuente que pueda surgir del uso de este sitio web o de la información contenida en él.",
      },
      {
        title: "Modificaciones",
        text: "Morán Franco se reserva el derecho de modificar, actualizar o eliminar cualquier contenido, término o condición de este sitio web sin previo aviso. Te recomendamos revisar periódicamente estos términos y condiciones para estar al tanto de cualquier cambio.",
      },
      {
        title: "Ley Aplicable",
        text: "Estos términos y condiciones se rigen por las leyes de España y cualquier controversia que surja en relación con este sitio web estará sujeta a la jurisdicción exclusiva de los tribunales de Madrid.",
      },
      {
        title: "Enlaces a Redes Sociales",
        text: "Morán Franco puede incluir enlaces a redes sociales, como Facebook, LinkedIn, Instagram, etc. Ten en cuenta que estos enlaces son solo para referencia y no implican que Morán Franco avale o tenga control sobre el contenido o las políticas de privacidad de dichas redes sociales.",
      },
      {
        title: "Recopilación de Información por Redes Sociales",
        text: "Cuando haces clic en un enlace a una red social en nuestro sitio web, es posible que la red social recopile información sobre tu visita. Esto puede incluir tu dirección IP, las páginas que visitas en nuestro sitio y puede asociarse con tu cuenta en la red social si estás conectado/a a ella en ese momento. Te recomendamos revisar las políticas de privacidad de las redes sociales que utilices para obtener más información sobre cómo recopilan y utilizan tus datos.",
      },
    ],
  },
  {
    path: "Aviso_Legal",
    title: "Aviso Legal",
    text: [
      {
        title: "",
        text: "En Morán Franco, nos tomamos la privacidad de nuestros visitantes muy en serio. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que puedas proporcionar al utilizar nuestro sitio web.",
      },
      {
        title: "Recopilación de Información",
        text: "Morán Franco no recopila información personal de los usuarios. No se solicitan ni almacenan nombres, direcciones, números de teléfono, direcciones de correo electrónico u otros datos personales.",
      },
      {
        title: "Uso de Cookies",
        text: "Morán Franco utiliza cookies técnicas y esenciales que son necesarias para el correcto funcionamiento del sitio web. Estas cookies no recopilan información personal y se eliminan automáticamente al cerrar el navegador. No se utilizan cookies de seguimiento ni tecnologías similares.",
      },
      {
        title: "Comunicaciones",
        text: "No enviamos correos electrónicos no solicitados ni promociones a nuestros visitantes, ya que no recopilamos direcciones de correo electrónico.",
      },
    ],
  },
  {
    path: "Politica_de_Cookies",
    title: "Politica de Cookies",
    text: [
      {
        title: "",
        text: "En Morán Franco, utilizamos cookies técnicas y esenciales para garantizar el correcto funcionamiento del sitio web. Estas cookies son necesarias para proporcionar los servicios y características ofrecidos en nuestro sitio y no recopilan información personal.",
      },
      {
        title: "",
        text: "Además de las cookies esenciales, no utilizamos cookies de seguimiento ni tecnologías similares para rastrear la actividad de nuestros visitantes o recopilar información personal.",
      },
    ],
  },
];

export const Gestiones: FC = () => {
  const [whatPolicy, setwhatPolicy] = useState<number>(0);
  const { id } = useParams();
  const seleccion = Texts.find((text) => text.path === id);
  useEffect(() => {
    if (seleccion !== undefined) {
      setwhatPolicy(Texts.indexOf(seleccion));
    }
  }, [seleccion]);
  if (seleccion !== undefined) {
    return (
      <ContainerAll>
        <ContainerTitle>
          <Title className="MainTitle">
            <h1>{seleccion.title}</h1>
          </Title>
        </ContainerTitle>
        <ContainerItem>
          {seleccion.text.map((text, index) => {
            return (
              <ContainerText>
                {text.title !== "" && (
                  <Title className="TitleItem">
                    <h2>{text.title}</h2>
                  </Title>
                )}
                <Text>
                  <p>{text.text}</p>
                </Text>
              </ContainerText>
            );
          })}
        </ContainerItem>
      </ContainerAll>
    );
  } else {
    return <ErrorFound />;
  }
};

const ContainerAll = styled.div``;

const ContainerTitle = styled.div`
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Privacidad});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Title = styled.div`
  &.MainTitle {
    width: 100%;
    text-align: center;
    color: white;
    @media screen and (min-width: 1025px) {
      padding: 0 20% 0 20%;
    }
    & h1 {
      &::after {
        //una barra baja
        content: "";
        display: block;
        width: 100%;
        height: 8px;
        background: rgb(155, 1, 1);
        background: linear-gradient(
          90deg,
          rgba(155, 1, 1, 1) 0%,
          rgba(218, 161, 161, 1) 70%,
          rgba(244, 244, 244, 1) 100%
        );
      }
    }
  }

  &.TitleItem {
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(155, 1, 1);
      background: linear-gradient(
        90deg,
        rgba(155, 1, 1, 1) 0%,
        rgba(218, 161, 161, 1) 70%,
        rgba(244, 244, 244, 1) 100%
      );
    }
  }
`;

const ContainerItem = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 3% 15% 3% 15%;
  }

  @media screen and (max-width: 1024px) {
    padding: 3% 6% 3% 6%;
  }
`;

const ContainerText = styled.div``;

const Text = styled.div``;
