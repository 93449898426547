import styled from "@emotion/styled";
import React, { FC } from "react";
import {
  Logo_Blanco,
  Slider_1,
  Slider_2,
  Slider_R,
  Item,
  RoberDespacho,
} from "../Components/Imports";

export const Home: FC = () => {
  const whoWeAre = [
    {
      texto:
        "Morán Franco es un bufete de abogados fundado en el año 2013 por Roberto Morán Franco, Profesor de Derecho y Abogado de los Colegios de Madrid y de León. Nuestro despacho se ha dedicado a lo largo de estos más de 10 años en exclusiva al Derecho Penal y la Extranjería, lo que nos ha convertido en uno de los bufetes más especializados de Madrid.",
      imagen: Slider_R,
    },
    {
      texto:
        "La dilatada experiencia de nuestro fundador, así como nuestros numerosos casos de éxito en el ámbito penal y de extranjería, nos abalan como uno de los bufetes de mayor éxito. La atención personalizada para cada cliente le garantiza la dedicación que merece.",
      imagen: Slider_1,
    },
    {
      texto:
        "El Despacho Jurídico Morán Franco es la mejor opción para la defensa de los intereses de la persona que se ve inmersa en un proceso judicial, porque nuestro cliente sabe que al confiar en nosotros cuenta con la experiencia, la profesionalidad y la lealtad de grandes profesionales en el ámbito de la defensa penal.",
      imagen: Slider_2,
    },
  ];

  const Eldespacho = [
    "Nuestro despacho esta compuesto por profesionales jurídicos altamente especialiazados en Derecho Penal, Derecho Procesal y Extranjería, que proporcionan a nuestro cliente la mejor defensa penal.",
  ];

  const Areas = [
    "Derecho Penal",
    "Delitos Contra la Salud Pública - Tráfico de Drogas",
    "Asistencia Letrada al Detenido",
    "Derecho Penitenciario",
    "Extranjería y Nacionalidad",
    "Cancelación de Antecedentes",
  ];
  return (
    <ContainerHome>
      <ContainerBrand>
        <ContainerImg className="ContainerImgBrand">
          <Brand src={Logo_Blanco} />
        </ContainerImg>
      </ContainerBrand>
      <ContainerDefinition className="ContianerHome">
        <ContainerItem className="Whoweare">
          <Titulo className="WhoweareTitle">
            <h1>Nuestro Bufete</h1>
          </Titulo>
          <ContainerText>
            {whoWeAre.map((text, index) => {
              return (
                <Text key={index}>
                  <p>{text.texto}</p>
                </Text>
              );
            })}
          </ContainerText>
        </ContainerItem>
        <ContainerItem className="ElDespacho">
          <ContainerImg className="Salon">
            <Image src={RoberDespacho} className="ImgSalon" />
          </ContainerImg>
          <ContainerItemText className="TextsDespacho">
            <Titulo className="TitleDespacho">
              <h1>El Despacho</h1>
            </Titulo>
            <ContainerText>
              <Text>
                {Eldespacho.map((text, index) => {
                  return <p key={index}>{text}</p>;
                })}
              </Text>
            </ContainerText>
          </ContainerItemText>
        </ContainerItem>
        {/* Rober */}
        <ContainerItem className="Areas">
          <Titulo className="AreasTitle">
            <h1>Áreas de trabajo</h1>
          </Titulo>
          <ContainerText className="AreaTextos">
            {Areas.map((text, index) => {
              return (
                <ContainerItemText className="ContainerItemText" key={index}>
                  <ContainerImg className={"Item"}>
                    <Image src={Item} className="Ella" />
                  </ContainerImg>
                  <Text className="TextAreas" key={index}>
                    <p>{text}</p>
                  </Text>
                </ContainerItemText>
              );
            })}
          </ContainerText>
        </ContainerItem>
      </ContainerDefinition>
    </ContainerHome>
  );
};
const ContainerHome = styled.div``;

const ContainerDefinition = styled.div`
  @media screen and (min-width: 1025px) {
  }
  @media screen and (max-width: 1024px) {
  }
`;
const ContainerBrand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(84, 84, 84);
  background: linear-gradient(45deg, black, black, #ffffff, black, black);
  background-size: 1000% 1000%;
  animation: flag 13s both infinite;
  padding: 0 20% 0 20%;

  @keyframes flag {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;
const Brand = styled.img`
  width: 100%;
  height: 100%;
`;

const Titulo = styled.div`
  &.WhoweareTitle {
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(155, 1, 1);
      background: linear-gradient(
        90deg,
        rgba(155, 1, 1, 1) 0%,
        rgba(218, 161, 161, 1) 70%,
        rgba(244, 244, 244, 1) 100%
      );
    }
  }
  &.AreasTitle {
    color: white;
    @media screen and (min-width: 1025px) {
      margin-bottom: 2%;
    }
    @media screen and (max-width: 1014px) {
      margin-bottom: 10%;
    }
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(155, 1, 1);
      background: linear-gradient(
        90deg,
        rgba(155, 1, 1, 1) 0%,
        rgba(218, 161, 161, 1) 70%,
        rgba(244, 244, 244, 1) 100%
      );
    }
  }
  &.TitleDespacho {
    width: 100%;
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(43, 73, 103);
      background: linear-gradient(
        90deg,
        rgba(43, 73, 103, 1) 0%,
        rgba(244, 244, 244, 1) 90%
      );
    }
  }
  &.TitleRober {
    width: 100%;
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(43, 73, 103);
      background: linear-gradient(
        90deg,
        rgba(43, 73, 103, 1) 0%,
        rgba(244, 244, 244, 1) 90%
      );
    }
  }
`;
const ContainerItem = styled.div`
  &.Whoweare {
    @media screen and (min-width: 1025px) {
      padding: 3% 15% 3% 15%;
    }

    @media screen and (max-width: 1024px) {
      padding: 3% 6% 3% 6%;
    }
  }
  &.Areas {
    padding: 3% 15% 3% 15%;
    background-image: url(${Slider_1});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    margin: 10% 0 10% 0;
  }

  &.ElDespacho {
    background-color: black;
    display: flex;
    @media screen and (min-width: 1025px) {
      flex-direction: row;
      justify-content: center;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    color: white;
  }

  &.Rober {
    display: flex;
    flex-direction: row-reverse;
    @media screen and (min-width: 1025px) {
      justify-content: center;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
`;

const ContainerText = styled.div`
  padding: 0 1% 0 1%;
  &.AreaTextos {
    @media screen and (min-width: 1025px) {
      padding: 2%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 1024) {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Text = styled.div`
  &.TextAreas {
    color: white;
  }
`;

const ContainerItemText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;

  &.TextsDespacho {
    display: flex;
    flex-direction: column;
    padding: 5%;
    @media screen and (min-width: 1025px) {
      width: 50%;
    }
  }

  &.TextsRober {
    display: flex;
    flex-direction: column;
    padding: 5%;
    @media screen and (min-width: 1025px) {
      width: 50%;
    }
  }
`;

const ContainerImg = styled.div`
  &.Salon {
    @media screen and (min-width: 1025px) {
      width: 50%;
      height: 50%;
      padding: 2%;
    }
  }
  &.ContIRober {
    @media screen and (min-width: 1025px) {
      width: 25%;
      height: 250%;
      padding: 2%;
    }
  }
  &.ContainerImgBrand {
    width: 90%;
    height: 90%;
  }
`;

const Image = styled.img`
  &.Ella {
    /* Convertir la imagen a escala de grises */
    filter: grayscale(100%);

    /* Ajustar el brillo para obtener el efecto blanco */
    filter: brightness(1000%);
  }

  &.ImgSalon {
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1025px) {
      border-radius: 5%;
    }
  }

  &.ImgRober {
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1025px) {
      border-radius: 5%;
    }
  }
`;
