import styled from "@emotion/styled";
import React, { FC } from "react";
import {
  Contacto,
  Ubicacion,
  Correo,
  Whatsapp_pic,
  Horario,
} from "../Components/Imports";

export const Contact: FC = () => {
  const Contactos = [
    {
      item: "Teléfono & Whatsapp",
      texto: "(+34) 640 82 83 54",
      img: Whatsapp_pic,
      paht: "https://api.whatsapp.com/send?phone=34640828354",
    },
    { item: "E-mail", texto: "moranfranco@icam.es", img: Correo, path: "" },
    {
      item: "Dirección",
      texto: "Avda. del Mediterráneo 7, 28007, Madrid",
      img: Ubicacion,
      path: "",
    },
    {
      item: "Horario de atención",
      texto: "De lunes a viernes \n Mañana: 10:30 a 15:00",
      img: Horario,
      path: "",
    },
  ];
  return (
    <ContainerContact className="ContainerContact">
      <ContainerMainImage className="ContainerMainImage">
        <Title className="Title">CONTACTO</Title>
      </ContainerMainImage>
      <ContainerInformation className="ContainerInformation">
        <ContainerText className="ContainerText">
          <Text>
            Si quiere contar con nosotros para el asesoramiento y defensa de su
            proceso, puede concretar una cita a través del teléfono, Whatsapp o
            correo electrónico y estudiaremos de forma meticulosa su caso para
            darle la solución más beneficiosa para usted.
          </Text>
        </ContainerText>
      </ContainerInformation>
      <TipoContactos className="TipesContact">
        {Contactos.map((item, index) => {
          return (
            <ContactItem key={index} className={"ContactItem" + (index + 1)}>
              <ContianerMaxImg>
                <ContainerImg className={"ContainerImg" + (index + 1)}>
                  <ImageItem icon={index + 1} src={item.img} />
                </ContainerImg>
              </ContianerMaxImg>
              <ContainerText className={"ContainerText" + (index + 1)}>
                <TitleItem className={"TitleItem" + (index + 1)}>
                  {item.item}
                </TitleItem>
                {item.texto.split("\n").map((item, index) => {
                  return (
                    <Text key={index} className={"Text" + (index + 1)}>
                      {item}
                    </Text>
                  );
                })}
                {item.item === "Teléfono & Whatsapp" && (
                  <Whatsapp href={item.paht} target="_blank">
                    <p>Inicia un chat</p>
                  </Whatsapp>
                )}
              </ContainerText>
            </ContactItem>
          );
        })}
      </TipoContactos>

      <Mapscontainer className="MapContainer">
        <Maps
          className="Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.057915582887!2d-3.67934602340734!3d40.407567756196016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42261be8e4d861%3A0x57fa23f15c007c3e!2sAv.%20del%20Mediterr%C3%A1neo%2C%207%2C%2028009%20Madrid!5e0!3m2!1ses!2ses!4v1693857866070!5m2!1ses!2ses"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Mapscontainer>
    </ContainerContact>
  );
};

//contenedor principal
const ContainerContact = styled.div``;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  color: #fff;
  &::after {
    //una barra baja
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background: rgb(155, 1, 1);
    background: linear-gradient(
      90deg,
      rgba(155, 1, 1, 1) 0%,
      rgba(218, 161, 161, 1) 70%,
      rgba(244, 244, 244, 1) 100%
    );
  }
`;
//contenedor de Informacion
const ContainerInformation = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 8% 20% 3% 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 100px;
    margin-bottom: 100px;
  }
  @media screen and (max-width: 1024px) {
    padding: 15% 5% 3% 5%;
  }
`;
//Contenedor de Formatos
const TipoContactos = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

  @media screen and (max-width: 1024) {
    display: flex;
    flex-direction: column;
  }
`;
const ContactItem = styled.div`
  display: grid;
  //en filas con todo los campos centrados
  grid-template-rows: 1fr 1fr;
  align-items: top;
  justify-content: center;
  padding: 5%;
  box-shadow: 0px 0px 10px 0px #d3d3d3;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
`;

const ContianerMaxImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 8px double rgb(155, 1, 1);
  width: 100px;
  height: 100px;
`;
const ImageItem = styled.img<{ icon: Number }>`
  max-width: ${(props) => (props.icon !== 4 ? "50%" : "70%")};
`;
const ContainerText = styled.div``;
const TitleItem = styled.h3`
  text-align: center;
`;
const Text = styled.p``;
//Mapa
const Mapscontainer = styled.div`
  background-color: black;
  padding: 0.5%;
  height: 500px;

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
`;
const Maps = styled.iframe`
  width: 100%;
  height: 100%;
`;

//Imagen
const ContainerMainImage = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Contacto});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (min-width: 1025px) {
    padding: 0 20% 0 20%;
  }
`;

const Whatsapp = styled.a`
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Hereda el color del texto del elemento padre */
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  border: 8px double rgb(155, 1, 1);

  & p {
    margin: 0;
  }
`;
