import styled from "@emotion/styled";
import React, { FC } from "react";
import {
  compromiso,
  confianza,
  discrección,
  experiencia,
  independencia,
  lealtad,
  profesionalidad,
  responsabilidad,
  secreto_profesional,
  Slider_3_oscuro,
} from "../Components/Imports";

export const Values: FC = () => {
  const Values = [
    {
      title: "Discrección",
      description:
        "El trabajo de un buen abogado se fundamenta en la consecución de los intereses del cliente de una forma eficaz pero también discreta.",
      image: discrección,
    },
    {
      title: "Experiencia",
      description:
        "Nuestros casos de éxito a lo largo de estos más de 10 años en la defensa de nuestros clientes, nos proporcionan unos conocimientos legales que ponemos a su servicio.",
      image: experiencia,
    },
    {
      title: "Compromiso",
      description:
        "Cuando usted decide contar con nuestro despacho para la defensa de sus intereses puede estar seguro que haremos todo lo que este en nuestras manos para defenderle.",
      image: compromiso,
    },

    {
      title: "Confianza",
      description:
        "El encargo profesional que usted nos realiza supone para nosotros un deber al que ponemos toda nuestra experiencia y nuestro tiempo, por lo que usted puede confiar en que sus intereses están en las mejores manos.",
      image: confianza,
    },
    {
      title: "Responsabilidad",
      description:
        "Somos conscientes de la importancia que para usted tienen los asuntos que nos confía, por eso actuamos con la máxima responsabilidad y diligencia.",
      image: responsabilidad,
    },
    {
      title: "Lealtad",
      description:
        "La lealtad que mantenemos hacia nuestros clientes garantiza que en el proceso penal o de extranjería priman los intereses del cliente, sin injerencias externas.",
      image: lealtad,
    },
    {
      title: "Profesionalidad",
      description:
        "Nuestra formación, experiencia e integridad nos configuran como uno de los mejores despachos penales y de extranjería de Madrid.",
      image: profesionalidad,
    },
    {
      title: "Secreto profesional",
      description:
        "Todo lo que nuestro cliente nos confía está amparado por el secreto profesional, no pudiendo ser revelado bajo ninguna circunstancia a otras personas.",
      image: secreto_profesional,
    },
    {
      title: "Independencia",
      description:
        "Trabajamos por y para usted, sin más compromiso o intereses que los suyos.",
      image: independencia,
    },
  ];

  const Texts_ = [
    "Nos diferencia ser líderes en la defensa penal, ello, unido al trato personal en cada caso, convierten nuestro servicio en una respuesta única en beneficio de quien en nosotros confía.",
    "Estamos comprometidos con el cliente y con nuestro entorno social, con objetivos globales de mejora de la sociedad en la que vivimos. Desde nuestro Despacho Jurídico tenemos la creencia de que la justicia y el acceso a la mejor defensa es un derecho esencial.",
    "Son nuestros profesionales los que hacen estos valores fáciles de entender para todos. Nuestro trabajo implica el compromiso de hacer propio el problema de quién cuenta con nuestro Despacho Jurídico, dando soluciones integradoras y de calidad, sin complicaciones, con protagonismo de la simplicidad en la gestión, con claridad y expectativas reales. El cliente sólo plantea el caso y nosotros le brindamos la mejor de las opciones, la que más le beneficie. ",
  ];
  return (
    <div>
      <ContainerMainImage className="ContainerMainImage">
        <Title className="Title">VALORES</Title>
      </ContainerMainImage>
      <Information className="Information">
        <Valuess values={Values.length} className="Values">
          {Values.map((item, index) => {
            return (
              <Value key={index} className={"Value" + (index + 1)}>
                <ContainerImg className={"ContainerImg Value" + (index + 1)}>
                  <Image src={item.image} />
                </ContainerImg>
                <Title className={"Text Value" + (index + 1)}>
                  {item.title}
                </Title>
                <Description className={"Description Value" + (index + 1)}>
                  <em>{item.description}</em>
                </Description>
              </Value>
            );
          })}
        </Valuess>
        <ContainerTexts>
          {Texts_.map((item, index) => {
            return (
              <Description key={index} className={"Texts" + (index + 1)}>
                {item}
              </Description>
            );
          })}
        </ContainerTexts>
      </Information>
    </div>
  );
};

const Information = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 10% 20% 3% 20%;
  }
  @media screen and (max-width: 1024px) {
    padding: 15% 5% 0 5%;
  }
`;
const Valuess = styled.div<{ values: number }>`
  height: 100%;
  width: 100%;
  @media screen and (min-width: 1025px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 20px;
  }

  @media screen and (max-width: 1342px/*1178*/) and (min-width: 1040px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(Math.ceil((${(props) => props.values})/2), 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 20px;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;
const Title = styled.h1`
  //ajustar el tamaño para que quede en una línea
  @media screen and (min-width: 1025px) {
    font-size: 2.4rem;
  }

  &.Title {
    width: 100%;
    text-align: center;
    color: #fff;
    &::after {
      //una barra baja
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      background: rgb(43, 73, 103);
      background: linear-gradient(
        90deg,
        rgba(43, 73, 103, 1) 0%,
        rgba(244, 244, 244, 1) 90%
      );
    }
  }
`;
const Description = styled.p`
  padding: 5px;
`;

const ContainerImg = styled.div`
  display: flex;
  justify-content: center;
  width: max(120px);
`;
const Image = styled.img`
  height: 200px;
  width: 200px;

  @media screen and (max-width: 1024px) {
    height: 150px;
    width: 150px;
  }
`;
const ContainerTexts = styled.div``;

// Title

const ContainerMainImage = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Slider_3_oscuro});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (min-width: 1025px) {
    padding: 0 20% 0 20%;
  }
`;
