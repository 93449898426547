import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import {
  Barrotes,
  Detenido,
  Extrangeria,
  Whatsapp_pic,
} from "../Components/Imports";
import { useParams } from "react-router-dom";
import { ErrorFound } from "./404";
import { WhatPage } from "../Props/Stylesprops";
const Texts = [
  {
    path: "Derecho_penal",
    title: "DERECHO PENAL",
    paragraphs: [
      {
        text: "El Derecho Penal es la potestad que tiene el Estado para castigar las conductas prohibidas mediante la Ley, concretamente las prohibidas en el Código Penal. A través del Orden Jurisdiccional Penal se tratan de imponer a una persona a la que se acusa de cometer un delito una pena (prisión, multa, inhabilitación…) o una medida de seguridad (internamiento en un centro médico, orden de alejamiento…). ",
        list: [],
      },
      {
        text: "Las penas y las medidas de seguridad solo pueden imponerse si previamente a su comisión han sido recogidas en el Código Penal. Para poder imponer una pena ha de destruirse, a través del correspondiente proceso penal, la presunción de inocencia. Esta solo puede enervarse cuando existen pruebas que permiten atribuir a una persona una o varias conductas que constituyen un delito, es decir, “nadie es culpable hasta que se demuestre lo contrario”. ",
        list: [],
      },
      {
        text: "El Derecho Penal, es la especialidad del Despacho Jurídico Morán Franco. En esta área llevamos trabajando desde hace más de 10 años. Nuestro exhaustivo trabajo a lo largo de estos años ha tenido gran éxito en numerosos procesos penales, tanto en instrucción, vistas orales y apelaciones, particularmente ejerciendo la defensa penal. ",
        list: [],
      },
      {
        text: "LOS DELITOS DE LOS QUE NOS OCUPAMOS ",
        list: [
          {
            title:
              "Homicidio. Asesinato. Lesiones dolosas.Lesiones imprudentes. Lesiones en el ámbito familiar (violencia de género).",
            paragraphs: [],
          },
          {
            title: "Detenciones ilegales y secuestros.",
            paragraphs: [],
          },
          {
            title: "Amenazas y Coacciones (también en el ámbito familiar).",
            paragraphs: [],
          },
          {
            title:
              "Torturas y Delitos contra la integridad moral (incluye el delito de maltrato habitual en el ámbito familiar).",
            paragraphs: [],
          },
          {
            title: "Trata de seres humanos.",
            paragraphs: [],
          },
          {
            title:
              "Agresiones y Abusos sexuales. Acoso sexual. Exhibicionismo y provocación sexual.",
            paragraphs: [],
          },
          {
            title: "Prostitución y explotación sexual; corrupción de menores.",
            paragraphs: [],
          },
          {
            title: "Omisión del deber de socorro.",
            paragraphs: [],
          },
          {
            title:
              "Descubrimiento y revelación de secretos; allanamiento de morada.",
            paragraphs: [],
          },
          {
            title: "Calumnias e Injurias.",
            paragraphs: [],
          },
          {
            title:
              "Sustracción de menores, abandono de familia (impago de pensiones…).",
            paragraphs: [],
          },
          {
            title:
              "Hurto. Robo con fuerza. Robo con violencia. Robo en casa habitada. Robo y hurto de uso de vehículos. Usurpación. Daños.",
            paragraphs: [],
          },
          {
            title: "Estafas. Administración desleal y Apropiación indebida.",
            paragraphs: [],
          },
          {
            title: "Alzamiento de bienes e Insolvencias punibles.",
            paragraphs: [],
          },
          {
            title:
              "Delitos contra la propiedad intelectual, industrial, contra los consumidores.",
            paragraphs: [],
          },
          {
            title: "Delitos Societarios.",
            paragraphs: [],
          },
          {
            title: "Blanqueo de Capitales. Receptación.",
            paragraphs: [
              {
                text: "Los Delitos Económicos, denominados en el Código Penal como Delitos contra el Patrimonio y el Orden Socioeconómico, consisten en obtener un beneficio económico en perjuicio de otra persona, por lo que tienen que darse siempre tres elementos de forma conjunta: un lucro económico, una intención de apropiarse y un engaño. Los delitos económicos más relevantes, sin hacer una enumeración exhaustiva son: ",
                list: [
                  {
                    title: "Delitos fiscales",
                    description:
                      "cometidos contra la Hacienda Pública por importe superior a 120.000 euros. Si la cantidad defraudada es inferior constituirá una infracción administrativa, sancionada con una multa.",
                  },
                  {
                    title: "Estafa",
                    description:
                      "a través del engaño a un tercero se consigue que este entregue una cantidad económica a cambio de un servicio o producto que no se tiene intención de prestar o entregar.",
                  },
                  {
                    title: "Apropiación indebida",
                    description:
                      "negativa a devolver a su legítimo propietario un bien que le fue entregado para su disfrute, traicionando la confianza del propietario. ",
                  },
                  {
                    title: "Malversación",
                    description:
                      "delito cometido por autoridades públicas o funcionarios, consistente en apropiarse de dinero público de manera indebida o administrado de forma desleal. ",
                  },
                  {
                    title: "Delitos de robo o hurto",
                    description:
                      "cometido cuando un sujeto se apropia de un bien mueble utilizando la violencia, fuerza en las cosas o intimidación, siendo estas características las que diferencian el delito de hurto del delito de robo.",
                  },
                ],
              },
              {
                text: "El Delito de Blanqueo de Capitales consiste en realizar actividades con el objetivo de incorporar al patrimonio legal de una persona bienes y cantidades económicas obtenidas mediante actividades prohibidas por la ley o delitos, dándole a esas cantidades económicas una apariencia de legalidad, lo que permite el disfrute de las mismas.",
                list: [],
              },
              {
                text: "Los Delitos Económicos se regulan en los arts. 234 a 297 del Código Penal, y el Delito de Blanqueo de Capitales en los arts. 298 a 304 del Código Penal. Los Delitos Económicos son castigados en función del tipo concreto de delito cometido, el Blanqueo de Capitales se castiga con prisión de 6 meses a 6 años.",
                list: [],
              },
              {
                text: "En Morán Franco estamos especializados en la defensa de los Delitos Económicos, y en particular, en la defensa de los Delitos de Blanqueo de Capitales, siendo este un tema en el que nuestro fundador ha desarrollado una actividad práctica y doctrinal abundante.",
                list: [],
              },
            ],
          },
          {
            title: "Financiación ilegal de partidos políticos.",
            paragraphs: [],
          },
          {
            title:
              "Delitos contra la Hacienda Pública y la Seguridad Social. Fraude de subvenciones.",
            paragraphs: [],
          },
          {
            title: "Delitos contra los Derechos de los Trabajadores.",
            paragraphs: [],
          },
          {
            title: "Delitos contra los derechos de los ciudadanos extranjeros.",
            paragraphs: [],
          },
          {
            title:
              "Ordenación del Territorio y Urbanismo. Protección del Patrimonio histórico y del Medio ambiente.",
            paragraphs: [],
          },
          {
            title: "Delitos de incendios: forestales, viviendas, locales…",
            paragraphs: [],
          },
          {
            title: "Delitos contra la salud pública: tráfico de DROGAS.",
            paragraphs: [
              {
                text: "Los Delitos contra la Salud Pública, comúnmente conocidos como delitos de tráfico de drogas, son un grupo de delitos consistentes en el cultivo, elaboración, transporte o venta de drogas tóxicas, estupefacientes y sustancias psicotrópicas, así como las actividades que promueva, favorezcan o faciliten el consumo de estas sustancias.",
                list: [],
              },
              {
                text: "El cultivo, elaboración, transporte o venta de droga se regula en los arts. 368 a 377 del Código Penal, castigándose, dependiendo de la conducta realizada y el grado de participación, con penas de prisión que van desde los 3 años de prisión a los 6 años, sin tener en cuenta las circunstancias agravantes, que podrían llevar la pena hasta los 18 años; y una multa que puede llegar hasta 5 veces el valor de la droga.",
                list: [],
              },
              {
                text: "El consumo o el transporte en la vía pública para el autoconsumo no constituyen un delito, sino que se configura como una infracción administrativa, castigada únicamente con la imposición de una sanción económica que va, desde los 601 euros hasta los 10.400 euros. ",
                list: [],
              },
              {
                text: "Los Delitos de Tráfico de Drogas son la especialidad del Despacho Jurídico Morán Franco. En este campo llevamos más de 10 años consiguiendo la absolución de un gran número de acusados y una notable rebaja de las penas solicitadas por el Ministerio Fiscal que han convertido a Roberto Morán Franco en uno de los juristas más exitosos y de mayor prestigio en este tipo de ilícitos penales.",
                list: [],
              },
            ],
          },
          {
            title:
              "Delitos contra la Seguridad Vial: consumo de alcohol, conducción temeraria, con grave desprecio a la vida, negativa a someterse a la prueba de alcoholemia,lesiones y homicidios por imprudencia.",
            paragraphs: [],
          },
          {
            title:
              "Falsedades: de moneda, de documentos públicos, oficiales y mercantiles, privados, certificados, tarjetas de crédito o débito.",
            paragraphs: [],
          },
          {
            title: "Usurpación de funciones públicas. Intrusismo.",
            paragraphs: [],
          },
          {
            title:
              "Prevaricación de funcionarios públicos, desobediencia y denegación de auxilio, infidelidad en la custodia de documentos y violación de secretos.",
            paragraphs: [],
          },
          {
            title: "Cohecho. Tráfico de influencias.",
            paragraphs: [],
          },
          {
            title: "Malversación de caudales públicos.",
            paragraphs: [],
          },
          {
            title:
              "Fraudes, Negociaciones y Actividades prohibidas a Funcionarios públicos.",
            paragraphs: [],
          },
          {
            title: "Prevaricación.",
            paragraphs: [],
          },
          {
            title: "Acusación y Denuncia falsas. Simulación de Delitos.",
            paragraphs: [],
          },
          {
            title: "Falso Testimonio. Obstrucción a la Justicia.",
            paragraphs: [],
          },
          {
            title: "Quebrantamiento de Condena y de Medida cautelar.",
            paragraphs: [],
          },
          {
            title:
              "Delitos contra la Constitución, contra la Corona y contra las Instituciones.",
            paragraphs: [],
          },
          {
            title:
              "Delitos relativos al ejercicio de los Derechos Fundamentales y las libertades públicas; contra la libertad de conciencia y los sentimientos religiosos; inviolabilidad domiciliaria.",
            paragraphs: [],
          },
          {
            title:
              " Atentados contra la autoridad, sus agentes o funcionarios. Resistencia. Desobediencia.",
            paragraphs: [],
          },
        ],
      },
    ],
  },
  {
    path: "Delitos_Contra_la_Salud_Pública",
    title: "DELITOS CONTRA LA SALUD PÚBLICA-TRÁFICO DE DROGAS",
    paragraphs: [
      {
        text: "Los Delitos contra la Salud Pública, comúnmente conocidos como delitos de tráfico de drogas, son un grupo de delitos consistentes en el cultivo, elaboración, transporte o venta de drogas tóxicas, estupefacientes y sustancias psicotrópicas, así como las actividades que promueva, favorezcan o faciliten el consumo de estas sustancias.",
        list: [],
      },
      {
        text: "El cultivo, elaboración, transporte o venta de droga se regula en los arts. 368 a 377 del Código Penal, castigándose, dependiendo de la conducta realizada y el grado de participación, con penas de prisión que van desde los 3 años de prisión a los 6 años, sin tener en cuenta las circunstancias agravantes, que podrían llevar la pena hasta los 18 años; y una multa que puede llegar hasta 5 veces el valor de la droga.",
        list: [],
      },
      {
        text: "El consumo o el transporte en la vía pública para el autoconsumo no constituyen un delito, sino que se configura como una infracción administrativa, castigada únicamente con la imposición de una sanción económica que va, desde los 601 euros hasta los 10.400 euros.",
        list: [],
      },
      {
        text: "Los Delitos de Tráfico de Drogas son la especialidad del Despacho Jurídico Morán Franco. En este campo llevamos más de 10 años consiguiendo la absolución de un gran número de acusados y una notable rebaja de las penas solicitadas por el Ministerio Fiscal que han convertido a Roberto Morán Franco en uno de los juristas más exitosos y de mayor prestigio en este tipo de ilícitos penales.",
        list: [],
      },
    ],
  },
  {
    path: "Asistencia_letrada_al_detenido",
    title: "ASISTENCIA LETRADA AL DETENIDO",
    paragraphs: [
      {
        text: "Una detención por parte de la Policía o la Guardia Civil, o simplemente la citación para declarar ante ellos o ante el Juez, supone para una persona una mala experiencia, un momento de tensión en el que el detenido o citado no sabe cómo actuar. Es importante mantener la calma, solicitar la asistencia letrada y no hablar con la policía hasta que haya llegado su abogado. La detención por la policía durará el tiempo imprescindible, y como máximo 72 horas.",
        list: [],
      },
      {
        text: "Desde el Despacho Jurídico Morán Franco proporcionamos una asistencia letrada al detenido en comisaria o en el Juzgado para que el acusado sea inmediatamente puesto en libertad.",
        list: [],
      },
      {
        text: "En caso de una detención o una citación debe ponerse inmediatamente en contacto con nosotros, uno de nuestros letrados se personará en el lugar de la detención para salvaguardar sus derechos y ser puesto en libertad o llevado ante el Juez lo más rápido posible.",
        list: [],
      },
    ],
  },
  {
    path: "Derecho_penitenciario",
    title: "DERECHO PENITENCIARIO  ",
    paragraphs: [
      {
        text: "El derecho penitenciario es el conjunto de normas que regulan la organización, el funcionamiento y la administración de las instituciones penitenciarias, así como la ejecución de las penas privativas de libertad. Su objetivo es garantizar que las personas que se encuentran privadas de su libertad sean tratadas de forma digna y humana, y de asegurar su reinserción social una vez que cumplen su condena.",
        list: [],
      },
      {
        text: "El derecho penitenciario se encuentra estrechamente relacionado con el derecho penal y con los derechos humanos. En este sentido, la ejecución de las penas privativas de libertad debe llevarse a cabo en cumplimiento de los estándares internacionales de derechos humanos y de las normas penales y procesales que se aplican en cada país.",
        list: [],
      },
      {
        text: "El derecho penitenciario aborda temas de gran importancia para garantizar el respeto de los Derechos Humanos de las personas privadas de libertad y su reinserción social. A continuación se muestran algunos de los principales temas que se abordan desde el Derecho Penitenciario:  ",
        list: [
          {
            title: "Ejecución de penas privativas de libertad",
            paragraphs: [
              {
                text: "El derecho penitenciario regula la forma en que se ejecutan las penas privativas de libertad. Así, establece procedimientos y requisitos que deben cumplir las instituciones penitenciarias para garantizar el respeto de los Derechos Humanos de las personas privadas de libertad. Entre otros aspectos, regula las condiciones de reclusión, el acceso a servicios básicos como la alimentación y la atención médica. También regula posibilidad de realizar actividades educativas, laborales y culturales.",
                list: [],
              },
            ],
          },
          {
            title: "Reinserción social",
            paragraphs: [
              {
                text: "de los objetivos fundamentales del derecho penitenciario es la reinserción social de las personas que se encuentran privadas de su libertad. En este sentido, se establecen programas de tratamiento y rehabilitación para las personas que han cometido delitos. El fin de los mismos es ayudarles a reintegrarse en la sociedad una vez que cumplen su condena. Estos programas pueden incluir actividades educativas, laborales, culturales y de apoyo psicológico.",
                list: [],
              },
            ],
          },
          {
            title: "Derechos humanos",
            paragraphs: [
              {
                text: "El derecho penitenciario se ocupa de garantizar el respeto de los derechos humanos de las personas privadas de libertad. Entre otros, se encuentran el derecho a la vida, el derecho a la integridad física y psicológica, el derecho a la libertad de expresión, el derecho a la atención médica, el derecho a la alimentación adecuada. Además, el derecho penitenciario también se preocupa por el trato justo y humano hacia los reclusos. Del mismo modo, se preocupa por garantizar el acceso a servicios y recursos necesarios para su bienestar.",
                list: [],
              },
            ],
          },
          {
            title: "Supervisión y control",
            paragraphs: [
              {
                text: "El derecho penitenciario también establece los mecanismos necesarios para supervisar y controlar el funcionamiento de las instituciones penitenciarias y garantizar que se respeten los derechos humanos de los reclusos. Esto incluye la creación de organismos de supervisión y control independientes o la implementación de protocolos de inspección y evaluación. También incluye la realización de investigaciones sobre denuncias de violaciones a los derechos humanos.",
                list: [],
              },
            ],
          },
          {
            title: "Protección de grupos vulnerables",
            paragraphs: [
              {
                text: "Las personas privadas de libertad que pertenecen a grupos vulnerables requieren de una atención especial en el marco del derecho penitenciario. En este sentido, se establecen medidas específicas para proteger sus derechos y garantizar su bienestar en el contexto de la privación de libertad. Pertenecen a estos grupos las mujeres, los niños y las niñas, los ancianos, las personas con discapacidad y los migrantes.",
                list: [],
              },
            ],
          },
          {
            title: "Respeto a la legalidad",
            paragraphs: [
              {
                text: " El derecho penitenciario también se preocupa por garantizar el respeto a la legalidad en el marco de la privación de libertad. Esto implica que la privación de libertad debe estar basada en la ley. Así, se deben respetar los derechos procesales de los reclusos, incluyendo el derecho a un juicio justo, la presunción de inocencia y la garantía de la apelación.",
                list: [],
              },
            ],
          },
        ],
      },
      {
        text: "Nuestro Despacho Jurídico se preocupa por que se ejercite la mejor de las defensas para las personas que confían en nosotros y velar por los derechos de aquellos que se encuentran presos, ya sea por una condena a prisión o por prisión provisional. Nuestros Letrados se ocupan de tratar de reducir la condena impuesta por los Tribunales mediante los recursos y procedimientos habilitados, así como velar por el bienestar, seguridad y salud de las personas que se encuentran en un centro penitenciario. Para nosotros es importante el trato con el cliente que se encuentra en prisión, por eso realizamos frecuentes visitas a prisión para velar por sus derechos.",
        list: [],
      },
      {
        text: "Nos ocupamos también de la cancelación de los antecedentes penales y los antecedentes policiales ante la Policía Nacional y la Guardia Civil. ",
        list: [],
      },
    ],
  },
  {
    path: "Extranjeria_y_nacionalidad",
    title: "EXTRANJERÍA Y NACIONALIDAD",
    paragraphs: [
      {
        text: "Desde el Despacho Jurídico Morán Franco hemos tramitado más de 3.000 solicitudes de arraigo y nacionalidad en los más de 10 años de ejercicio.",
        list: [
          {
            title: "Arraigo Social",
            paragraphs: [],
          },
          {
            title: "Arraigo Laboral",
            paragraphs: [],
          },
          {
            title: "Arraigo Familiar",
            paragraphs: [],
          },
          {
            title: "Arraigo por Formación",
            paragraphs: [],
          },
          {
            title: "Nacionalidad",
            paragraphs: [],
          },
          {
            title: "Reagrupación Familiar",
            paragraphs: [],
          },
          {
            title: "Cancelación de Antecedentes Penales y Policiales",
            paragraphs: [],
          },
          {
            title: "Recursos Contenciosos de Arraigo o Nacionalidad",
            paragraphs: [],
          },
          {
            title: "Matrimonio y Pareja de Hecho en España",
            paragraphs: [],
          },
          {
            title: "Contratación de trabajadores extranjeros",
            paragraphs: [],
          },
          {
            title: "Delitos cometidos contra los extranjeros",
            paragraphs: [],
          },
        ],
      },
    ],
  },
  {
    path: "Cancelacion_de_antecedentes_penales_y_policiales",
    title: "CANCELACION DE ANTECEDENTES PENALES Y POLICIALES ",
    paragraphs: [
      {
        text: "Desde el Despacho Jurídico Morán Franco nos ocupamos de la cancelación de los antecedentes penales y de los antecedentes policiales. ",
        list: [],
      },
      {
        text: "Los antecedentes penales son aquellos antecedentes que se derivan de condenas privativas de libertad, multa o trabajos en beneficio de la comunidad impuestas por Jueces y Tribunales. Transcurrido el tiempo que establece la Ley, los antecedentes se pueden cancelar mediante un procedimiento a través del Ministerio de Justicia y del Tribunal que condenó.",
        list: [],
      },
      {
        text: "El plazo necesario para poder cancelar los antecedentes penales es el siguiente",
        list: [
          {
            title: "Seis meses para las penas leves.",
            paragraphs: [],
          },
          {
            title:
              "Dos años para las penas que no excedan de doce meses y las impuestas por delitos imprudentes.",
            paragraphs: [],
          },
          {
            title:
              "Tres años para las restantes penas menos graves inferiores a tres años.",
            paragraphs: [],
          },
          {
            title:
              "Cinco años para las restantes penas menos graves iguales o superiores a tres años.",
            paragraphs: [],
          },
          {
            title: "Diez años para las penas graves.",
            paragraphs: [],
          },
        ],
      },
      {
        text: "El plazo se cuenta desde el día siguiente a aquel en el que queda extinguida la pena.",
        list: [],
      },
      {
        text: "Clasificación de las penas para la cancelación de antecedentes penales. Según establece el artículo 33 del Código Penal las penas pueden agruparse de la siguiente forma",
        list: [
          {
            title: "Penas graves",
            paragraphs: [
              {
                text: "El plazo de cancelación de antecedentes penales en penas graves es de 10 años. Se consideran penas graves las siguientes",
                list: [
                  { title: "Prisión permanente revisable", description: "" },
                  { title: "Prisión superior a cinco años.", description: "" },
                  { title: "Inhabilitación absoluta.", description: "" },
                  {
                    title:
                      "Inhabilitaciones especiales por tiempo superior a cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Suspensión de empleo o cargo público por tiempo superior a cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a conducir vehículos a motor y ciclomotores por tiempo superior a ocho años.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a la tenencia y porte de armas por tiempo superior a ocho años.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a residir en determinados lugares o acudir a ellos, por tiempo superior a cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Prohibición de aproximarse a la víctima o a aquellos de sus familiares u otras personas que determine el juez o tribunal, por tiempo superior a cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Prohibición de comunicarse con la víctima o con aquellos de sus familiares u otras personas que determine el juez o tribunal, por tiempo superior a cinco años.",
                    description: "",
                  },
                  {
                    title: "Privación de la patria potestad.",
                    description: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Penas menos graves",
            paragraphs: [
              {
                text: "En el caso de las penas menos graves hay tres plazos diferentes de cancelación de antecedentes penales según la pena aplicada",
                list: [
                  {
                    title:
                      "Cuando la pena es inferior a doce meses y para penas por delitos imprudentes, el plazo de cancelación es de dos años.",
                    description: "",
                  },
                  {
                    title:
                      "Cuando la pena es inferior a tres años, el plazo de cancelación es de tres años.",
                    description: "",
                  },
                  {
                    title:
                      "Cuando la pena es igual o superior a tres años, el plazo de cancelación es de cinco años.",
                    description: "",
                  },
                ],
              },
              {
                text: "Se consideran penas menos graves los siguientes",
                list: [
                  {
                    title: "Prisión de tres meses hasta cinco años.",
                    description: "",
                  },
                  {
                    title: "Inhabilitaciones especiales hasta cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Suspensión de empleo o cargo público hasta cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a conducir vehículos a motor y ciclomotores de un año y un día a ocho años.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a la tenencia y porte de armas de un año y un día a ocho años.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a residir en determinados lugares o acudir a ellos, por tiempo de seis meses a cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Prohibición de aproximarse a la víctima o a aquellos de sus familiares u otras personas que determine el juez o tribunal, por tiempo de seis meses a cinco años.",
                    description: "",
                  },
                  {
                    title:
                      "Prohibición de comunicarse con la víctima o con aquellos de sus familiares u otras personas que determine el juez o tribunal, por tiempo de seis meses a cinco años.",
                    description: "",
                  },
                  { title: "Multa de más de tres meses.", description: "" },
                  {
                    title:
                      "La multa proporcional, cualquiera que fuese su cuantía, salvo lo dispuesto en el apartado 7 de este artículo.",
                    description: "",
                  },
                  {
                    title:
                      "Trabajos en beneficio de la comunidad de treinta y un días a un año.",
                    description: "",
                  },
                ],
              },
            ],
          },
          {
            title: "Penas leves",
            paragraphs: [
              {
                text: "El plazo de cancelación de antecedentes penales en penas leves es de 6 meses. Se consideran penas leves las siguientes",
                list: [
                  {
                    title:
                      "Privación del derecho a conducir vehículos a motor y ciclomotores de tres meses a un año.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a la tenencia y porte de armas de tres meses a un año.",
                    description: "",
                  },
                  {
                    title:
                      "Inhabilitación especial para el ejercicio de profesión, oficio o comercio que tenga relación con los animales y para la tenencia de animales de tres meses a un año.",
                    description: "",
                  },
                  {
                    title:
                      "Privación del derecho a residir en determinados lugares o acudir a ellos, por tiempo inferior a seis meses.",
                    description: "",
                  },
                  {
                    title:
                      "Prohibición de aproximarse a la víctima o a aquellos de sus familiares u otras personas que determine el juez o tribunal, por tiempo de un mes a menos de seis meses.",
                    description: "",
                  },
                  {
                    title:
                      "Prohibición de comunicarse con la víctima o con aquellos de sus familiares u otras personas que determine el juez o tribunal, por tiempo de un mes a menos de seis meses.",
                    description: "",
                  },
                  { title: "Multa de hasta tres meses.", description: "" },
                  {
                    title: "Localización permanente de un día a tres meses.",
                    description: "",
                  },
                  {
                    title:
                      "Trabajos en beneficio de la comunidad de uno a 30 días.",
                    description: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const Contactos = [
  {
    item: "Teléfono & Whatsapp",
    texto: "(+34) 640 82 83 54",
    img: Whatsapp_pic,
    paht: "https://api.whatsapp.com/send?phone=34640828354",
  },
];

export const Activity: FC = () => {
  const [activity, setActivity] = useState<number>(0);

  const { id } = useParams();
  const seleccion = Texts.find((text) => text.path === id);
  useEffect(() => {
    if (seleccion !== undefined) {
      setActivity(Texts.indexOf(seleccion));
    }
  }, [seleccion]);
  if (seleccion !== undefined) {
    return (
      <ActivityContainer className="ActivityContainer">
        <MainHead
          className="MainHead"
          page={{
            what_page: {
              page: activity,
            },
          }}
        >
          <MainTitle
            className="MainTitle"
            page={{
              what_page: {
                page: activity,
              },
            }}
          >
            <h1>{Texts[activity].title}</h1>
          </MainTitle>
        </MainHead>
        <ContainerDescription className="ContainerDescription">
          {Texts[activity].paragraphs.map((paragraph, index) => {
            return (
              <Description
                key={"Description " + (index + 1)}
                className="Description"
              >
                {paragraph.text !== "" && (
                  <Text className={"Text " + (index + 1)}>
                    <p>{paragraph.text}</p>
                  </Text>
                )}
                {paragraph.list.length > 0 && (
                  <List className={"FirstList"}>
                    {paragraph.list.map((list, index) => {
                      return (
                        <ItemList
                          className={"ItemList" + (index + 1)}
                          key={"ItemList " + (index + 1)}
                        >
                          <SecondTitle
                            className="SecondTitle"
                            page={{
                              what_page: {
                                page: activity,
                              },
                            }}
                          >
                            <p>{list.title}</p>
                          </SecondTitle>
                          {list.paragraphs.length > 0 && (
                            <Expand className={"Expand" + (index + 1)}>
                              {list.paragraphs.map((paragraph, index) => {
                                return (
                                  <SecondDescription
                                    key={"SecondDescription" + (index + 1)}
                                    className={
                                      "SecondDescription" + (index + 1)
                                    }
                                  >
                                    <p>{paragraph.text}</p>
                                    {paragraph.list.length > 0 && (
                                      <List className="SecondList">
                                        {paragraph.list.map(
                                          (paragraph, index) => {
                                            return (
                                              <ItemList
                                                key={
                                                  "SecondItemList" + (index + 1)
                                                }
                                              >
                                                <ThirdTitle>
                                                  <p>{paragraph.title}</p>
                                                </ThirdTitle>
                                                <ThirdDescription>
                                                  <p>{paragraph.description}</p>
                                                </ThirdDescription>
                                              </ItemList>
                                            );
                                          }
                                        )}
                                      </List>
                                    )}
                                  </SecondDescription>
                                );
                              })}
                            </Expand>
                          )}
                        </ItemList>
                      );
                    })}
                  </List>
                )}
              </Description>
            );
          })}
          {activity == 2 && (
            <TipoContactos className="TipesContact">
              {Contactos.map((item, index) => {
                return (
                  <ContactItem
                    key={index}
                    className={"ContactItem" + (index + 1)}
                  >
                    <ContianerMaxImg>
                      <ContainerImg className={"ContainerImg" + (index + 1)}>
                        <ImageItem icon={index + 1} src={item.img} />
                      </ContainerImg>
                    </ContianerMaxImg>
                    <ContainerText className={"ContainerText" + (index + 1)}>
                      <TitleItem className={"TitleItem" + (index + 1)}>
                        {item.item}
                      </TitleItem>
                      {item.texto.split("\n").map((item, index) => {
                        return (
                          <Text key={index} className={"Text" + (index + 1)}>
                            <p>{item}</p>
                          </Text>
                        );
                      })}
                      {item.item === "Teléfono & Whatsapp" && (
                        <Whatsapp href={item.paht} target="_blank">
                          <p>Inicia un chat</p>
                        </Whatsapp>
                      )}
                    </ContainerText>
                  </ContactItem>
                );
              })}
            </TipoContactos>
          )}
        </ContainerDescription>
      </ActivityContainer>
    );
  } else {
    return <ErrorFound />;
  }
};

const ActivityContainer = styled.div`
  margin-bottom: 5%;
`;

const MainHead = styled.div<{ page: WhatPage }>`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) =>
    props.page.what_page.page === 1
      ? "url(" + Detenido + ")"
      : props.page.what_page.page === 4
      ? "url(" + Extrangeria + ")"
      : "url(" + Barrotes + ")"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (min-width: 1025px) {
    padding: 0 20% 0 20%;
  }
`;

const MainTitle = styled.div<{ page: WhatPage }>`
  width: 100%;
  text-align: center;
  color: white;

  &::after {
    //una barra baja
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background: ${(props) =>
      props.page.what_page.page === 1 || props.page.what_page.page === 4
        ? "rgb(43, 73, 103);"
        : "rgb(155, 1, 1)"};
    background: ${(props) =>
      props.page.what_page.page === 1 || props.page.what_page.page === 4
        ? "linear-gradient(90deg,rgba(43, 73, 103, 1) 0%,rgba(244, 244, 244, 1) 90%);"
        : "linear-gradient(90deg,rgba(155, 1, 1, 1) 0%,rgba(218, 161, 161, 1) 70%,rgba(244, 244, 244, 1) 100%);"};
  }
`;

const ContainerDescription = styled.div`
  @media screen and (min-width: 1025px) {
    padding: 0 20% 0 20%;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 6% 0 6%;
  }
`;

const Description = styled.div`
  margin: 5% 0 0 0;
`;

const Text = styled.div`
  &p {
    font-size: large;
  }
`;

const List = styled.ul`
  //cambiar el punto pir un número

  &.FirstList {
    list-style: upper-roman;
    padding: 0 5% 0 5%;
  }

  & .SecondList {
    list-style: circle;
  }
`;

const ItemList = styled.li`
  margin: 0;
`;

const SecondTitle = styled.div<{ page: WhatPage }>`
  //una barra baja
  //texto negrita
  font-weight: bold;
  &::after {
    //una barra baja
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: ${(props) =>
      props.page.what_page.page === 1 || props.page.what_page.page === 4
        ? "rgb(43, 73, 103);"
        : "rgb(155, 1, 1)"};
    background: ${(props) =>
      props.page.what_page.page === 1 || props.page.what_page.page === 4
        ? "linear-gradient(90deg,rgba(43, 73, 103, 1) 0%,rgba(244, 244, 244, 1) 90%);"
        : "linear-gradient(90deg,rgba(155, 1, 1, 1) 0%,rgba(218, 161, 161, 1) 70%,rgba(244, 244, 244, 1) 100%);"};
  }
`;

const Expand = styled.div``;

const SecondDescription = styled.div``;

const ThirdTitle = styled.div``;

const ThirdDescription = styled.div``;

////// Whatsap
const TipoContactos = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContactItem = styled.div`
  display: grid;
  //en filas con todo los campos centrados
  grid-template-rows: 1fr 1fr;
  align-items: top;
  justify-content: center;
  padding: 5%;
  box-shadow: 0px 0px 10px 0px #d3d3d3;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
`;

const ContianerMaxImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 8px double rgb(155, 1, 1);
  width: 100px;
  height: 100px;
`;
const ImageItem = styled.img<{ icon: Number }>`
  max-width: ${(props) => (props.icon !== 4 ? "50%" : "70%")};
`;
const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const TitleItem = styled.h3`
  text-align: center;
`;

const Whatsapp = styled.a`
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Hereda el color del texto del elemento padre */
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  border: 8px double rgb(155, 1, 1);
  padding: 2%;
  & p {
    margin: 0;
  }
`;
