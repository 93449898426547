import styled from "@emotion/styled";
import React, { FC } from "react";
import { Slider_2, Roberto_Moran } from "../Components/Imports";

export const Rober: FC = () => {
  const TextsRober = [
    "Licenciado en Derecho por la Universidad de León en el año 2012.",
    "Cuenta con 3 Masters Oficiales de especialización en diferentes ramas del Derecho por las Universidades de León, del Atlántico y Alfonso X el Sabio.",
    "Curso de Práctica Procesal Penal por el Colegio de la Abogacía de Madrid.",
    "Curso de Especialización en Fraude Fiscal y Blanqueo de Capitales por la Universidad de León.",
    "Profesor de Derecho en diferentes Facultades e Institutos de la Comunidad de Madrid.",
    "Curso de Especialización en Mediación Laboral por la Universidad de León.",
    "Miembro de los Consejos de Administración de varias empresas.",
    "Abogado de los Colegios de Madrid y León en ejercicio desde el año 2013.",
  ];

  return (
    <ContainerAll>
      <ContainerBrand className="ContainerFotoPrincipal">
        <Titulo className="Principal">
          <h1>ROBERTO MORÁN FRANCO</h1>
        </Titulo>
      </ContainerBrand>
      <ContainerRobert className="ContainerRobert">
        <Titulo className="TitlePage">
          <h1>Abogado Penalista y de Extranjería</h1>
        </Titulo>
        <CoantianerInformations className="ContainerInofrmations">
          <ContainerImg className="ContainerImageRober">
            <Image src={Roberto_Moran} className="ImageRober" />
          </ContainerImg>
          <ContainerInfo className="ContainerInfo">
            {TextsRober.map((text, index) => {
              return (
                <Text key={index}>
                  <p>{text}</p>
                </Text>
              );
            })}
          </ContainerInfo>
        </CoantianerInformations>
      </ContainerRobert>
    </ContainerAll>
  );
};

const ContainerAll = styled.div``;
const ContainerBrand = styled.div`
  background-image: url(${Slider_2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 40%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerRobert = styled.div``;
const ContainerImg = styled.div`
  &.ContainerImageRober {
  }
`;
const Image = styled.img`
  &.ImageRober {
    width: 100%;
    max-height: 100%;
    filter: grayscale();

    @media screen and (min-width: 1025px) {
      border-radius: 10%;
      border: 8px double #2b4967;
    }
  }
`;
const Titulo = styled.div`
  &.Principal {
    color: white;
    width: 100%;
    & h1 {
      text-align: center;
    }
  }

  &.TitlePage {
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20vh;
    color: #9b0101;

    & h2 {
      margin: 0;
    }
  }
`;

const CoantianerInformations = styled.div`
  background-color: black;
  @media screen and (min-width: 1025px) {
    padding: 2% 15% 10% 15%;
    display: flex;
    flex-direction: row;

    gap: 5%;
  }
`;

const ContainerInfo = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-evenly;

  @media screen and (min-width: 1025px) {
    padding: 1%;
  }

  @media screen and (max-width: 1024px) {
    padding: 10%;
  }
`;

const Text = styled.li`
  color: white;
  & p {
    font-size: larger;
  }
`;
