import React, { FC } from "react";
import { Cabecera } from "../Components/Header";
import { FootPage } from "../Components/FootPage";
import { Values } from "./Values";
import { LawFirm } from "./LawFirm";
import { Activity } from "./Activity";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorFound } from "./404";
import { Home } from "./Home";
import { Rober } from "./Roberto";
import { Gestiones } from "./Gestiones";
import { Contact } from "./Contact";
export const Principal: FC = () => {
  return (
    <>
      <BrowserRouter>
        <Cabecera />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<LawFirm />} path="/LawFirm" />
          <Route element={<Rober />} path="/Roberto_Moran" />
          <Route element={<Activity />} path="/Activity/:id" />
          <Route element={<Values />} path="/Values" />
          <Route element={<Contact />} path="/Contact" />
          <Route element={<Gestiones />} path="/:id" />
          <Route element={<ErrorFound />} path="*" />
        </Routes>
        <FootPage />
      </BrowserRouter>
    </>
  );
};
