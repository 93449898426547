import styled from "@emotion/styled";
import React, { FC } from "react";
/* Imagenes */
import {
  Logo_Blanco,
  Logo_abogados,
  FacebookBlanco,
  InstagramBlanco,
  LinkedInBlanco,
  TikTok,
} from "./Imports";
import { useNavigate } from "react-router-dom";

const Social_media = [
  {
    name: "LinkedIn",
    img: LinkedInBlanco,
    path: "https://www.linkedin.com/in/roberto-mor%C3%A1n-franco-256932153",
  },
  {
    name: "Instagram",
    img: InstagramBlanco,
    path: "https://instagram.com/moranfrancodespacho?igshid=OGQ5ZDc2ODk2ZA==",
  },
  {
    name: "Facebook",
    img: FacebookBlanco,
    path: "https://www.facebook.com/profile.php?id=61554762337971",
  },
  {
    name: "TikTok",
    img: TikTok,
    path: "https://www.tiktok.com/@moranfrancodespacho?_t=8fQeBjhiQU5&_r=1",
  },
];

const Textos_necessarios = [
  { text: "Política de privacidad", path: "/Politica_de_Privacidad" },
  { text: "Aviso Legal", path: "/Aviso_Legal" },
  { text: "Política de Cookies", path: "/Politica_de_Cookies" },
];

export const FootPage: FC = () => {
  const navigate = useNavigate();
  return (
    <ContainerAll className="ContianerFooter">
      <Principal className="Principal">
        <ContainerBox className="Box1">
          <Information className="Information">
            <Titulo className="Despacho">
              <h2>MORÁN FRANCO</h2>
              <h3>DESPACHO JURÍDICO</h3>
            </Titulo>
            <Presentation className="Presentation1">
              <p>
                Morán Franco es un despacho de abogados penalistas y de
                extranjería con sede en Madrid. Protegemos a nuestros clientes
                en toda España.
              </p>
            </Presentation>
          </Information>
          <Contact className="Contact">
            <Titulo className="Contact">
              <h2>Contacto</h2>
            </Titulo>
            <Presentation className="Presentation2">
              <p>TEL:(+34) 640 82 83 54</p>
              <p>E-Mail: moranfranco@icam.es</p>
              <p>Avenida del Mediterráneo 7, 28007, Madrid</p>
              <p>Siguenos en nuestras redes</p>
              <SocialMedia className="Social media">
                {Social_media.map((social, index) => {
                  return (
                    <Network
                      className={"Network" + social.name}
                      key={index}
                      href={social.path}
                      target="_blank"
                    >
                      <ContainerImg className={"LogoNetwork" + social.name}>
                        <Image
                          src={social.img}
                          className={"Img" + social.name}
                        />
                      </ContainerImg>
                    </Network>
                  );
                })}
              </SocialMedia>
            </Presentation>
          </Contact>
        </ContainerBox>
        <ContainerBox className="Box2">
          <ContainerImg className="LogoDespacho">
            <Image src={Logo_Blanco} />
          </ContainerImg>
          <ContainerImg className="LogoAbogados">
            <Image src={Logo_abogados} />
          </ContainerImg>
        </ContainerBox>
      </Principal>
      <Needs>
        <Need className="CopyRight">
          <p>© 2023 Morán Franco</p>
        </Need>
        {Textos_necessarios.map((texto, index) => {
          return (
            <Need
              key={index}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(texto.path);
              }}
            >
              <p>{texto.text}</p>
            </Need>
          );
        })}
      </Needs>
    </ContainerAll>
  );
};
const ContainerAll = styled.div`
  background-color: black;

  @media screen and (min-width: 1025px) {
    padding: 4% 10% 3% 10%;
  }
  @media screen and (max-width: 1024px) {
    padding: 15% 5% 3% 5%;
  }
`;

const Principal = styled.div`
  display: flex;
  @media screen and (min-width: 1015px) {
    flex-direction: row;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  justify-content: space-around;
`;
const ContainerBox = styled.div`
  &.Box1 {
    padding-top: 2.2%;
    display: flex;
    @media screen and (min-width: 1025px) {
      width: 70%;
      flex-direction: row;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &.Box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 1025px) {
      width: 30%;
    }
    @media screen and (max-width: 1024px) {
    }
  }
`;

const Information = styled.div`
  @media screen and (min-width: 1025px) {
    width: 50%;
    padding: 0 7% 0 0;
    & p {
      font-size: large;
    }
  }
`;
const Contact = styled.div`
  @media screen and (min-width: 1025px) {
    & p {
      font-size: large;
      margin: 1% 0 0 0;
    }
  }
`;
const Presentation = styled.div`
  color: white;
`;
const ContainerImg = styled.div`
  &.LogoDespacho {
    max-width: 70%;
  }
  &.LogoAbogados {
    max-width: 50%;
  }

  &.LogoNetworkLinkedIn {
    max-width: 35%;
    // border-radius: 100%;
    //background-color: #0077b5;
  }

  &.LogoNetworkInstagram {
    max-width: 40%;
    /* border-radius: 100%;
    background: rgb(64, 93, 230);
    background: linear-gradient(
      90deg,
      rgba(64, 93, 230, 1) 0%,
      rgba(91, 81, 216, 1) 10%,
      rgba(131, 58, 180, 1) 20%,
      rgba(193, 53, 132, 1) 30%,
      rgba(225, 48, 108, 1) 40%,
      rgba(253, 29, 29, 1) 50%,
      rgba(245, 96, 64, 1) 60%,
      rgba(247, 119, 55, 1) 70%,
      rgba(252, 175, 69, 1) 80%,
      rgba(255, 220, 128, 1) 90%
    );*/
  }

  &.LogoNetworkFacebook {
    max-width: 35%;
  }

  &.LogoNetworkTikTok {
    max-width: 70%;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;

  &.ImgFacebook {
    border-radius: 30%;
    background-color: #ffffff;
  }
`;
const Titulo = styled.div`
  color: white;
  & h2 {
    font-size: larger;
  }
  & h3 {
    font-size: large;
  }
`;

const Needs = styled.div`
  color: white;
  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
`;
const Need = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  & p {
    font-size: small;
  }

  @keyframes colorchange {
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 6% 0 0 0;
`;

const Network = styled.a`
  display: flex;
  height: max-content;
  max-width: 50%;
  justify-content: center;
`;
