import styled from "@emotion/styled";
import React, { FC, useState } from "react";
/*Imagenes */
import { Logo_Blanco } from "./Imports";
import { useNavigate } from "react-router-dom";
import { Pages_props } from "../Props/Stylesprops";
export const Cabecera: FC = () => {
  const navigate = useNavigate();

  const Pages_ = [
    {
      text: "Inicio",
      path: "/",
      SubOptions: [],
    },

    {
      text: "Quienes somos",
      path: "LawFirm",
      SubOptions: [
        { text: "El Despacho", path: "LawFirm" },
        { text: "Valores", path: "Values" },
        { text: "Roberto Morán", path: "Roberto_Moran" },
      ],
    },

    {
      text: "Áreas de Actividad",
      path: "/Activity/Derecho_penal",
      SubOptions: [
        { text: "Derecho Penal", path: "/Activity/Derecho_penal" },
        {
          text: "Delitos Contra la Salud Publica",
          path: "/Activity/Delitos_Contra_la_Salud_Pública",
        },
        {
          text: "Asistencia Letrada al Detenido",
          path: "/Activity/Asistencia_letrada_al_detenido",
        },
        {
          text: "Derecho Penitenciario",
          path: "/Activity/Derecho_penitenciario",
        },
        {
          text: "Extranjería y Nacionalidad",
          path: "/Activity/Extranjeria_y_nacionalidad",
        },
        {
          text: "Cancelacion de Antecedentes penales y policiales",
          path: "/Activity/Cancelacion_de_antecedentes_penales_y_policiales",
        },
      ],
    },

    {
      text: "Contacto",
      path: "Contact",
      SubOptions: [],
    },
    // { text: "Artículos", path: "Articles"}
    // { text: "Actualidad", path: "News" },
  ];

  const [HamburguerResponsive, setHamburguer] = useState<boolean>(false);
  const [ViewSubOptions, setViewSubOptions] = useState<Array<boolean>>(
    Array(Pages_.length).fill(false)
  );

  return (
    <>
      <BrandArea className="BrandArea">
        <ContainerImg className="Container_Img">
          <Imagen
            src={Logo_Blanco}
            alt="Logo Principal"
            className="Imagen_logo"
            onClick={() => {
              setHamburguer(false);
              window.scrollTo(0, 0);
              navigate("/");
            }}
          />
        </ContainerImg>

        <Navegacion className="Navegacion">
          <HamburguerContainer className="HamburguerContainer">
            <HamburguerBottom
              active={HamburguerResponsive}
              onClick={() => {
                setHamburguer(!HamburguerResponsive);
                setViewSubOptions(Array(Pages_.length).fill(false));
              }}
              className="HamburguerBottom"
            >
              {Array(3)
                .fill(0)
                .map((_, index) => {
                  return (
                    <span
                      key={index}
                      className={"LineHamburguer" + (index + 1)}
                    />
                  );
                })}
            </HamburguerBottom>
          </HamburguerContainer>
          <Pages
            props={{
              elements: {
                activate: HamburguerResponsive,
                num_pages: Pages_.length + 1,
              },
            }}
            className="Pages"
          >
            {Pages_.map((page, index) => {
              return (
                <Page
                  className={`Pagina_${index + 1}`}
                  key={index}
                  id={"pagina" + (index + 1)}
                >
                  <ChooseOptions
                    className={"ChooseOptions Page " + (index + 1)}
                  >
                    <Clicked
                      className="Clicked"
                      onClick={() => {
                        setHamburguer(false);
                        setViewSubOptions(Array(Pages_.length).fill(false));
                        window.scrollTo(0, 0);
                        navigate(page.path);
                      }}
                    >
                      <p>{page.text.toUpperCase()}</p>
                    </Clicked>
                    <SuboptionsArrow
                      className="SuboptionsArrow"
                      suboptionsNumber={page.SubOptions.length}
                      onClick={() => {
                        setViewSubOptions(
                          ViewSubOptions.map((_, index_) => {
                            return index_ === index
                              ? !ViewSubOptions[index_]
                              : ViewSubOptions[index_];
                          })
                        );
                      }}
                    >
                      <ContainerArrows
                        className="ContainerArrows"
                        ViewOptions={ViewSubOptions[index]}
                      >
                        {Array(4)
                          .fill(0)
                          .map((_, index) => {
                            return (
                              <span
                                key={index}
                                className={"Line Row" + (index + 1)}
                              ></span>
                            );
                          })}
                      </ContainerArrows>
                    </SuboptionsArrow>
                  </ChooseOptions>
                  {page.SubOptions.length > 0 && (
                    <Subopciones
                      className="Subopciones"
                      id="Subopciones"
                      ViewOptions={ViewSubOptions[index]}
                    >
                      {page.SubOptions.map((suboption, index) => {
                        return (
                          <Subopcion
                            className="Subopcion"
                            key={index}
                            onClick={() => {
                              setViewSubOptions(
                                Array(Pages_.length).fill(false)
                              );
                              setHamburguer(false);
                              window.scrollTo(0, 0);
                              navigate(suboption.path);
                            }}
                          >
                            <p>{suboption.text}</p>
                          </Subopcion>
                        );
                      })}
                    </Subopciones>
                  )}
                </Page>
              );
            })}
          </Pages>
        </Navegacion>
      </BrandArea>
    </>
  );
};

const BrandArea = styled.div`
  background-color: black;
  padding: 0 10% 0 10%;
  display: flex;
  @media screen and (max-width: 1025px) {
    /*D <= 1025px*/
    flex-wrap: wrap;
  }
`;
const ContainerImg = styled.div`
  width: max(22%);
  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;
const Imagen = styled.img`
  width: 100%;
`;
const Navegacion = styled.nav`
  width: 100%;
`;
const Pages = styled.div<{ props: Pages_props }>`
  display: flex;
  @media screen and (min-width: 1025px) {
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: stretch;
  }
  @media screen and (max-width: 1024px) {
    display: ${(props) => (props.props.elements.activate ? "flex" : "none")};
    flex-direction: column;
    animation-name: show_hide;
    animation-duration: 2.5s;
    animation-iteration-count: 1;
    animation-direction: ${(props) =>
      props.props.elements.activate ? "normal" : "reverse"};
  }

  @keyframes show_hide {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
`;
const Page = styled.div`
  display: flex;

  margin: 10px;
  & p {
    font-size: 20px;
    color: white;
  }

  @media screen and (min-width: 1025px) {
    position: relative;
    &:hover {
      cursor: pointer;
      & #Subopciones {
        display: block;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const ChooseOptions = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1025px) {
    & p {
      text-align: left;
    }
  }
`;
const Clicked = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
const SuboptionsArrow = styled.div<{ suboptionsNumber: number }>`
  display: none;
  box-shadow: -2px 0px #2c2c2c;
  @media screen and (max-width: 1024px) {
    display: ${(props) => (props.suboptionsNumber > 0 ? "block" : "none")};
    position: relative;
    width: 50%;
  }
`;
const ContainerArrows = styled.div<{ ViewOptions: Boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-75%, -50%, 0) rotate(-45deg);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0, 1) 0.2s;
  width: 30px;
  height: 30px;

  div {
    transform: ${(props) =>
      props.ViewOptions ? "translate3d(-25%, -50%, 0) rotate(-45deg)" : ""};
  }
  span {
    position: absolute;
    background: white;

    &:nth-of-type(odd) {
      width: 4px;
      top: 0;
      bottom: 0;
    }

    &:nth-of-type(even) {
      height: 4px;
      left: 0;
      right: 0;
    }

    // right

    &:nth-of-type(1) {
      right: 0;
      transform: ${(props) => (props.ViewOptions ? "scale3d(1, 0, 1)" : "")};
      transform-origin: ${(props) => (props.ViewOptions ? "top" : "bottom")};
      transition: ${(props) =>
        props.ViewOptions
          ? "transform 0.15s linear 0.3s"
          : "transform 0.3s cubic-bezier(0.1, 0.5, 0.2, 1) 0.4s"};
    }

    &:nth-of-type(2) {
      bottom: 0;
      transform: ${(props) => (props.ViewOptions ? "scale3d(0, 1, 1)" : "")};
      transform-origin: ${(props) => (props.ViewOptions ? "right" : "left")};
      transition: ${(props) =>
        props.ViewOptions
          ? "transform 0.2s cubic-bezier(0.69, 0, 0.94, 1)"
          : "transform 0.15s linear 0.3s"};
    }

    // left
    &:nth-of-type(3) {
      left: 0;
      transform-origin: ${(props) => (props.ViewOptions ? "top" : "bottom")};
      transform: ${(props) =>
        props.ViewOptions ? "scale3d(1, 1, 1)" : "scale3d(1, 0, 1)"};
      transition: ${(props) =>
        props.ViewOptions
          ? "transform 0.3s cubic-bezier(0.1, 0.5, 0.2, 1) 0.4s"
          : "transform 0.15s linear 0.3s"};
    }

    &:nth-of-type(4) {
      top: 0;
      transform-origin: ${(props) => (props.ViewOptions ? "right" : "left")};
      transform: ${(props) =>
        props.ViewOptions ? "scale3d(1, 1, 1)" : "scale3d(0, 1, 1)"};
      transition: ${(props) =>
        props.ViewOptions
          ? "transform 0.15s linear 0.3s;"
          : "transform 0.2s cubic-bezier(0.69, 0, 0.94, 1)"};
    }
  }
`;
/**Homburguesa */

const HamburguerContainer = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const HamburguerBottom = styled.div<{ active: Boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transform-origin: center center;
  & span {
    width: 4rem;
    height: 0.5em;
    background-color: ${(props) => (props.active ? "red" : "white")};
    border-radius: 5rem;
    margin-bottom: 0.8rem;
  }
  & span:first-of-type {
    transition: ease 1s;
  }
  & span:nth-of-type(2) {
    transition: ease 1s;
  }
  & span:last-of-type {
    transition: ease 1s;
  }
  & span:first-of-type {
    transform: ${(props) =>
      props.active ? "matrix(0.8, -0.8, 0.8, 0.586, 0, 20)" : ""};
  }
  & span:nth-of-type(2) {
    transform: ${(props) => (props.active ? "translateX(-5000px)" : "")};
    visibility: ${(props) => (props.active ? "hidden" : "")};
  }
  & span:last-of-type {
    transform: ${(props) =>
      props.active ? "matrix(0.8, 0.8, -0.8, 0.586, 0, -20)" : ""};
  }
`;
/*Sub Opciones */
const Subopciones = styled.div<{ ViewOptions: Boolean }>`
  padding: 5px;
  @media screen and (min-width: 1025px) {
    display: none;
    background-color: black;
    position: absolute;
    color: white;
    top: 100%;
    width: max-content;
    border: 1px inset #d3d3d3;
  }

  @media screen and (max-width: 1024px) {
    display: ${(props) => (props.ViewOptions ? "block" : "none")};
    animation-name: show_hide;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-direction: ${(props) =>
      props.ViewOptions ? "normal" : "reverse"};
    & p {
      text-align: left;
    }
  }
  @keyframes show_hide {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
`;
const Subopcion = styled.div`
  padding: 15px;
  margin: 0;
  @media screen and (min-width: 1025px) {
    z-index: 1;
    box-shadow: 0px -1px #2c2c2c;
  }
  p {
    padding: 0;
    @media screen and (min-width: 1025px) {
      margin: 0;
    }
    @media screen and (max-width: 1024px) {
      margin: 0 0 0 15%;
    }
  }
  &:nth-of-type(1) {
    margin-top: 10px;
  }
`;
