import styled from "@emotion/styled";
import React, { FC } from "react";
import { Firma } from "../Components/Imports";
import { useNavigate } from "react-router-dom";
export const ErrorFound: FC = () => {
  const navigate = useNavigate();

  return (
    <ContainerError>
      <Error> Error 404 - Esta paǵina no pertenece a nuestro dominio. </Error>
      <Regreso
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/");
        }}
      >
        <h2>Pagina de inicio</h2>
      </Regreso>
    </ContainerError>
  );
};

const ContainerError = styled.div`
  height: 60vh;
  background-image: url(${Firma});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1025px) {
    padding: 4% 10% 3% 10%;
  }
  @media screen and (max-width: 1024px) {
    padding: 15% 5% 3% 5%;
  }
`;
const Error = styled.h1`
  color: white;
`;

const Regreso = styled.div`
  margin-top: 10%;
  @media screen and (min-width: 1025px) {
    padding: 1%;
  }

  @media screen and (max-width: 1024px) {
    padding: 5%;
  }

  border-radius: 5%;
  border: 8px inset white;
  cursor: pointer;
  color: white;

  & h2 {
    margin: 0;
  }
`;
